import HammerSlider from "../../../_snowpack/pkg/hammer-slider.js";
document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".banner-rotation").forEach((sliderElement) => {
    let options = {
      slideShow: false,
      slideInterval: 5e3,
      slideSpeed: 50,
      touchSpeed: 50,
      startSlide: 0,
      dragThreshold: 10,
      minimumDragDistance: 30,
      stopAfterInteraction: true,
      stopOnHover: true,
      rewind: false,
      dots: false,
      mouseDrag: false,
      dotContainer: void 0,
      slideContainer: void 0,
      beforeSlideChange: void 0,
      afterSlideChange: void 0,
      onSetup: void 0,
      cssPrefix: "banner-rotation"
    };
    for (const key in options) {
      let value = sliderElement.dataset[key.toLowerCase()];
      if (typeof value !== "undefined") {
        options[key] = value;
      }
    }
    const slider = HammerSlider(sliderElement, options);
    if (options.stopOnHover) {
      sliderElement.addEventListener("mouseenter", slider.stop(), false);
      sliderElement.addEventListener("mouseleave", slider.start(), false);
    }
    sliderElement.querySelector(`.${options.cssPrefix}__btn--left`).addEventListener("click", slider.prev);
    sliderElement.querySelector(`.${options.cssPrefix}__btn--right`).addEventListener("click", slider.next);
  });
});
