window.addEventListener("message", (event) => {
  if (event.origin != "https://tools.communicationads.net") {
    return;
  }
  let data = event.data;
  if (!data) {
    return;
  }
  if (typeof data != "string") {
    return;
  }
  let [elementId, height] = data.split("|");
  let selector = elementId ? `#${elementId}` : 'iframe[src^="https://tools.communicationads.net/"]';
  document.querySelectorAll(selector).forEach((element) => element.style.height = `${height}px`);
}, false);
const assignUniqueId = (iframe) => {
  if (iframe.id) {
    return;
  }
  let elementId = iframe.src.match(/[a-z]+\.php/)[0].replace(/\.php/, "").replace(/rechner/, "");
  while (document.getElementById(elementId)) {
    elementId += Math.floor(1e4 * Math.random() + 1);
  }
  iframe.id = elementId;
  iframe.setAttribute("id", elementId);
};
document.querySelectorAll('iframe[src^="https://tools.communicationads.net/"]').forEach((iframe) => {
  iframe.onload = () => {
    assignUniqueId(iframe);
    iframe.contentWindow.postMessage(iframe.id, "*");
  };
});
